<template>
  <Main>
    <template v-slot:content>
      <div class="home container">
        <h1 class="home__title">
          <b> {{ $t("message.Please_select_your_service") }} </b><br />
          {{ $t("message.you_can_also_select_multiple") }}
        </h1>

        <div class="home__items">
          <div v-for="item in services" :key="item.name" class="home__item">
            <div class="home__item-image">
              <img v-if="item.media.length" :src="item.media[0]" :alt="item.title" />
            </div>
            <div class="home__item-content">
              <div class="home__item-title">{{ item.title }}</div>
              <div class="home__item-description">{{ item.description }}</div>
              <template v-if="item.features">
                <div class="home__item-list">
                  <div
                    v-for="(feature, index) in item.features"
                    :key="index"
                    class="home__item-list-item"
                  >
                    <ListArrow />
                    {{ feature }}
                  </div>
                </div>
              </template>
              <div class="home__item-bottom">
                <div class="home__item-bottom-price">
                  <template v-if="item.price.amount == 0"> {{ $t("message.Free") }} </template>
                  <template v-if="item.price.amount > 0">{{ item.price_formatted }}</template>
                </div>
                <div class="home__item-bottom-nav">
                  <div
                    @click="createProject('trial')"
                    v-if="item.name == 'trial'"
                    class="home__item-bottom-button"
                  >
                    {{ $t("message.Try") }}
                  </div>
                  <div
                    v-else
                    @click="handleChooseService(item.name)"
                    :class="['home__item-bottom-checkbox', chosenService[item.name] && 'active']"
                  >
                    <ListArrow />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home__bottom">
          <div @click="createProject()" class="home__bottom-button">{{ $t("message.Next") }}</div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <Footer />
    </template>
  </Main>
</template>

<script>
import baseApi from "~/api/base";
import projectApi from "~/api/project";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import ListArrow from "~/assets/images/icons/list-arrow.svg";
import Footer from "~/components/layouts/Footer.vue";

export default {
  data() {
    return {
      services: [],
      chosenService: {
        add_furniture: false,
        remove_furniture: false,
        renovate: false,
      },
    };
  },
  mounted() {
    this.getListServices();
  },
  components: {
    Main,
    ListArrow,
    Footer,
  },
  methods: {
    getListServices() {
      try {
        baseApi
          .getListServices()
          .then((res) => {
            this.services = res;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    async createProject(item = null) {
      const projects = [];

      if (item) {
        projects.push(item);
      } else {
        const keys = Object.keys(this.chosenService);

        for (let i = 0; keys.length > i; i++) {
          if (this.chosenService[keys[i]]) {
            projects.push(keys[i]);
          }
        }
      }

      const projects_id = [];

      for (let e = 0; projects.length > e; e++) {
        const data = {
          service_type: projects[e],
        };
        try {
          await projectApi
            .createProject(data)
            .then((res) => {
              projects_id.push(res.id);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        } catch (error) {
          console.log(error);
        }
      }

      if (projects_id.length > 1) {
        let url = `?projects=`;
        for (let a = 0; projects_id.length > a; a++) {
          if (projects_id.length == a + 1) {
            url += `${projects_id[a]}`;
          } else {
            url += `${projects_id[a]},`;
          }
        }

        this.$router.push({
          path: ROUTE.PROJECT.replace(":id", `${projects_id[0]}`) + url,
        });
      } else {
        this.$router.push({
          path: ROUTE.PROJECT.replace(":id", `${projects_id[0]}`),
        });
      }
    },
    handleChooseService(item) {
      this.chosenService[item] = !this.chosenService[item];
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__title {
    padding-top: 40px;
    font-size: 24px;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 45px;
  }

  &__item {
    width: calc(50% - 12px);
    display: flex;
    border-radius: 15px;
    border: 1px solid #191919;
    background: #f9f9f9;
    margin-bottom: 24px;
    overflow: hidden;
  }

  &__item-image {
    width: 50%;
    height: 100%;
    min-width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  &__item-content {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    font-size: 24px;
    padding-bottom: 10px;
    font-family: "Mirador";
  }

  &__item-description {
    font-size: 16px;
    color: #8e8e8e;
    font-weight: 600;
    padding-bottom: 10px;
  }

  &__item-list-item {
    padding-bottom: 10px;

    svg {
      margin-right: 5px;
    }
  }

  &__item-bottom {
    padding-top: 10px;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item-bottom-price {
    font-size: 18px;
    font-family: "Mirador";
  }

  &__item-bottom-button {
    border-radius: 8px;
    background: #191919;
    color: #fff;
    font-size: 14px;
    padding: 7px 20px;
    cursor: pointer;
    transition: all ease-in-out 0.5s;

    &:hover {
      opacity: 0.8;
      transition: all ease-in-out 0.5s;
    }
  }

  &__item-bottom-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid #adadad;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;
    cursor: pointer;

    svg {
      display: none;
    }

    &.active {
      svg {
        display: block;
      }
    }
  }

  &__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 6px;
    border-top: 1px solid #d7d9dd;
    display: flex;
    justify-content: flex-end;
  }

  &__bottom-button {
    border-radius: 8px;
    background: #191919;
    color: #fff;
    font-size: 16px;
    padding: 7px 40px;
    cursor: pointer;
    transition: all ease-in-out 0.5s;

    &:hover {
      opacity: 0.8;
      transition: all ease-in-out 0.5s;
    }
  }

  @media (max-width: 1350px) {
    &__title {
      font-size: 20px;
      padding-top: 30px;
    }

    &__items {
      padding-top: 35px;
    }

    &__item-title {
      font-size: 20px;
      padding: 5px 0 5px;
    }

    &__item-description {
      font-size: 14px;
    }

    &__item-list-item {
      font-size: 14px;
    }

    &__item-bottom-price {
      font-size: 20px;
    }

    &__item-bottom-button {
      font-size: 14px;
    }

    &__item-bottom-checkbox {
      width: 20px;
      height: 20px;
    }

    &__bottom {
      border-top: none;
      padding-top: 0;
    }
  }

  @media (max-width: 991px) {
    &__item {
      width: 100%;
    }

    &__item-title {
      font-size: 24px;
      padding: 10px 0 10px;
    }

    &__item-description {
      font-size: 16px;
    }

    &__item-list-item {
      font-size: 16px;
    }

    &__item-bottom-price {
      font-size: 24px;
    }

    &__item-bottom-button {
      font-size: 16px;
    }
  }

  @media (max-width: 568px) {
    &__title {
      font-size: 16px;
      padding-top: 10px;
    }

    &__items {
      padding-top: 15px;
    }

    &__item {
      flex-direction: column;
    }

    &__item-image {
      width: 100%;
    }

    &__item-title {
      font-size: 20px;
      padding: 5px 0 5px;
    }

    &__item-bottom {
      padding-top: 80px;
    }

    &__item-description {
      font-size: 14px;
    }

    &__item-list-item {
      font-size: 14px;
    }

    &__item-bottom-price {
      font-size: 20px;
    }

    &__item-bottom-button {
      font-size: 14px;
    }

    &__item-bottom-checkbox {
      width: 20px;
      height: 20px;
    }

    &__bottom {
      border-top: none;
      padding-top: 0;
    }
  }
}
</style>
