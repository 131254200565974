<template>
  <div class="footer">
    <div class="footer__container container">
      <div class="footer__item">
        {{ $t("message.If_you_have_any_questions") }}
      </div>
      <a href="mailto:info@simple-staging.com" class="footer__item">
        <Mail />
        info@simple-staging.com
      </a>
      <a href="tel:+496980883723" class="footer__item">
        <Tel />
        +49 69 8088 3723
      </a>
    </div>
  </div>
</template>

<script>
import Mail from "~/assets/images/icons/mail.svg";
import Tel from "~/assets/images/icons/tel.svg";
export default {
  name: "Footer",
  components: {
    Mail,
    Tel,
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 30px 0;
  background: #f9f9f9;
  border-top: 1px solid #d7d9dd;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    color: #191919;
    font-size: 16px;
    padding: 0 20px;
    display: flex;
    align-items: center;

    &:hover {
      color: #191919;
    }

    svg {
      margin-right: 10px;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;

    &__container {
      flex-wrap: wrap;
    }

    &__item {
      padding: 5px 20px;
    }
  }
}
</style>
